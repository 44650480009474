import React from 'react';
import Carousel from 'react-material-ui-carousel'
import Item from './Item';
import imageSlider from './imageSliderArray';

function Example()
{
    return (
        <div style={{marginTop: "72px"}}>
        <Carousel
        indicatorContainerProps={{
            style: {
                zIndex: 1,
                marginTop: "-10px",
                position: "relative",
                display: "none"
            }
    
        }}
        >
            {
                imageSlider.map( (item, index) => <Item key={index} item={item} /> )
            }
        </Carousel>
        </div>
    )
}

export default Example