import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import Homepage from "./pages/Homepage";
import Glosario from "./pages/Glosario";
import Infografias from "./pages/Infografias";
import InfografiaChol from "./pages/Infografias/InfografiaChol";
import InfografiaChontal from "./pages/Infografias/InfografiaChontal";
import InfografiaEspanol from "./pages/Infografias/InfografiaEspanol";
import InfografiaMixteco from "./pages/Infografias/InfografiaMixteco";
import InfografiaNahuatl from "./pages/Infografias/InfografiaNahuatl";
import InfografiaPopoluca from "./pages/Infografias/InfografiaPopoluca";
import InfografiaTenek from "./pages/Infografias/InfografiaTenek";

const theme = createTheme({
  palette: {
    primary: {
      main: "#E87722",
    }
  },
});

function App() {
  return (
    <>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage/>}/>
          <Route path="/glosario" element={<Glosario/>}/>
          <Route path="/infografias" element={<Infografias/>}/>
          <Route path="/infografiachol" element={<InfografiaChol/>}/>
          <Route path="/infografiachontal" element={<InfografiaChontal/>}/>
          <Route path="/infografiaespanol" element={<InfografiaEspanol/>}/>
          <Route path="/infografiamixteco" element={<InfografiaMixteco/>}/>
          <Route path="/infografianahuatl" element={<InfografiaNahuatl/>}/>
          <Route path="/infografiapopoluca" element={<InfografiaPopoluca/>}/>
          <Route path="/infografiatenek" element={<InfografiaTenek/>}/>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
    </>
  );
}

export default App;
