import { Box, Grid, Typography} from '@mui/material';
import { styled } from '@mui/material/styles';
import link1 from '../assets/indice/titulo-chol.png';
import link2 from '../assets/indice/titulo-chontal.png';
import link3 from '../assets/indice/titulo-espanol.png';
import link4 from '../assets/indice/titulo-mixteco.png';
import link5 from '../assets/indice/titulo-nahuatl.png';
import link6 from '../assets/indice/titulo-popoluca.png';
import link7 from '../assets/indice/titulo-tenek.png';
import DownloadIcon from '@mui/icons-material/Download';
import { Link } from 'react-router-dom';

const GridTitles = styled(Grid)(() => ({
    textAlign: "center"
}))

const Indice = () => {
  return (
    <section style={{marginTop: "112px"}}>
    <Box sx={{flexGrow: 1, margin:6}}>
        <Typography variant='h5' sx={{ margin:"50px 20px", textAlign:"center", color:"#e87722", fontWeight:"bold"}}>
            Selecciona la lengua de tu preferencia y escucha los audios <br /> <br/> Da click en el enlace de descarga para obtener la infografía en formato PDF
        </Typography>
        <Grid container spacing={1}>
            <GridTitles xs={12} md={6} sx={{marginBottom:1, display:"flex", alignItems:"center", justifyContent:"center"}}>
                <Link
                    style={{ width:"400px"}}
                    to="/infografiachol"
                >
                    <img style={{width: "95%"}} src={link1} alt="chol" />
                </Link>
                <a
                href="./pdf/Infografia_Chol.pdf" download>
                    <DownloadIcon color="primary" fontSize='large'/>
                </a>
            </GridTitles>
            <GridTitles xs={12} md={6} sx={{marginBottom:1, display:"flex", alignItems:"center", justifyContent:"center"}}>
                <Link 
                    style={{ width:"400px"}}
                    to="/infografiachontal"
                >
                    <img style={{width: "95%"}} src={link2} alt="chontal" />
                </Link>
                <a href="./pdf/Infografia_Chontal.pdf" download>
                    <DownloadIcon color="primary" fontSize='large'/>
                </a>
            </GridTitles>
            <GridTitles xs={12} md={6} sx={{marginBottom:1, display:"flex", alignItems:"center", justifyContent:"center"}}>
                <Link 
                    style={{ width:"400px"}}
                    to="/infografiaespanol"
                >
                    <img style={{width: "95%"}} src={link3} alt="espanol" />
                </Link>
                <a href="./pdf/Infografia_Espanol.pdf" download>
                    <DownloadIcon color="primary" fontSize='large'/>
                </a>
            </GridTitles>
            <GridTitles xs={12} md={6} sx={{marginBottom:1, display:"flex", alignItems:"center", justifyContent:"center"}}>
                <Link 
                    style={{ width:"400px"}}
                    to="/infografiamixteco"
                >
                    <img style={{width: "95%"}} src={link4} alt="mixteco" />
                </Link>
                <a href="./pdf/Infografia_Mixteco.pdf" download>
                    <DownloadIcon color="primary" fontSize='large'/>
                </a>
            </GridTitles>
            <GridTitles xs={12} md={6} sx={{marginBottom:1, display:"flex", alignItems:"center", justifyContent:"center"}}>
                <Link 
                    style={{ width:"400px"}}
                    to="/infografianahuatl"
                >
                        <img style={{width: "95%"}} src={link5} alt="nahuatl" />
                </Link>
                <a href="./pdf/Infografia_Nahuatl.pdf" download>
                    <DownloadIcon color="primary" fontSize='large'/>
                </a>
            </GridTitles>
            <GridTitles xs={12} md={6} sx={{marginBottom:1, display:"flex", alignItems:"center", justifyContent:"center"}}>
                <Link 
                    style={{ width:"400px"}}
                    to="/infografiapopoluca"
                >
                        <img style={{width: "95%"}} src={link6} alt="popoluca" />
                </Link>
                <a href="./pdf/Infografia_Popoluca.pdf" download>
                    <DownloadIcon color="primary" fontSize='large'/>
                </a>
            </GridTitles>
            <GridTitles xs={12} md={6} sx={{marginBottom:1, display:"flex", alignItems:"center", justifyContent:"center"}}>
                <Link 
                    style={{ width:"400px"}}
                    to="/infografiatenek"
                >
                    <img style={{width: "95%"}} src={link7} alt="tenek" />
                </Link>
                <a href="./pdf/Infografia_Tenek.pdf" download>
                    <DownloadIcon color="primary" fontSize='large'/>
                </a>
            </GridTitles>
        </Grid>
    </Box>
    </section>
  )
}

export default Indice