import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import GlosarioChol from '../components/GlosarioChol'
import GlosarioEspanol from '../components/GlosarioEspanol'
import GlosarioNahuatl from '../components/GlosarioNahuatl'
import GlosarioPopoluca from '../components/GlosarioPopoluca'
import GlosarioTenek from '../components/GlosarioTenek'
import './glosario.css'
import { useState } from 'react'
import styled from 'styled-components'

const Glosario = () => {
    
    const [language, setLanguage] = useState('chol');
    const [close, setClose] = useState(1)

    function openGlossary (lang) {
        setLanguage(lang);
        setClose(1)
    }

    function openTab() {
        setClose(0)
    }
      
    function closeTab() {
        setClose(1)
    }

    const NavigationClose = styled.nav`
        @media only screen and (max-width: 550px) {
            width: 0
        }
    `

    const NavigationOpen = styled.nav`

        @media only screen and (max-width: 550px) {
            width: 80%
        }
    `
        if(language === "chol") {
        return (
            <>
                <Navbar/>
                <button class="btn-open" onClick={openTab}>☰</button>
                <div className="container">
                { close === 0 ?
                    <NavigationOpen id="sidetab" className="tab">
                        <a href="javascript:void(0)" class="btn-close" onClick={closeTab}>×</a>
                        <button className="tablinks" onClick={() => openGlossary("chol")} id="default">Ch'ol</button>
                        <button className="tablinks" onClick={() => openGlossary("espanol")}>Español</button>
                        <button className="tablinks" onClick={() => openGlossary("nahuatl")}>Náhuatl</button>
                        <button className="tablinks" onClick={() => openGlossary("popoluca")}>Popoluca</button>
                        <button className="tablinks" onClick={() => openGlossary("tenek")}>Tének</button>
                    </NavigationOpen>
                    :
                    <NavigationClose id="sidetab" className="tab">
                        <a href="javascript:void(0)" class="btn-close" onClick={closeTab}>×</a>
                        <button className="tablinks" style={{backgroundColor: "white", color:"#E87722"}} onClick={() => openGlossary("chol")} id="default">Ch'ol</button>
                        <button className="tablinks" onClick={() => openGlossary("espanol")}>Español</button>
                        <button className="tablinks" onClick={() => openGlossary("nahuatl")}>Náhuatl</button>
                        <button className="tablinks" onClick={() => openGlossary("popoluca")}>Popoluca</button>
                        <button className="tablinks" onClick={() => openGlossary("tenek")}>Tének</button>
                    </NavigationClose>
                }
                
                <div className="glossary">
                    <GlosarioChol/>
    
                </div>
                </div>
                <Footer/>
            </>
        )
    }
    else if (language === "espanol") {
        return (
            <>
                <Navbar/>
                <button class="btn-open" onClick={openTab}>☰</button>
                <div className="container">
                { close === 0 ?
                    <NavigationOpen id="sidetab" className="tab">
                        <a href="javascript:void(0)" class="btn-close" onClick={closeTab}>×</a>
                        <button className="tablinks" onClick={() => openGlossary("chol")} id="default">Ch'ol</button>
                        <button className="tablinks" onClick={() => openGlossary("espanol")}>Español</button>
                        <button className="tablinks" onClick={() => openGlossary("nahuatl")}>Náhuatl</button>
                        <button className="tablinks" onClick={() => openGlossary("popoluca")}>Popoluca</button>
                        <button className="tablinks" onClick={() => openGlossary("tenek")}>Tének</button>
                    </NavigationOpen>
                    :
                    <NavigationClose id="sidetab" className="tab">
                        <a href="javascript:void(0)" class="btn-close" onClick={closeTab}>×</a>
                        <button className="tablinks" onClick={() => openGlossary("chol")} id="default">Ch'ol</button>
                        <button className="tablinks"  style={{backgroundColor: "white", color:"#E87722"}}  onClick={() => openGlossary("espanol")}>Español</button>
                        <button className="tablinks" onClick={() => openGlossary("nahuatl")}>Náhuatl</button>
                        <button className="tablinks" onClick={() => openGlossary("popoluca")}>Popoluca</button>
                        <button className="tablinks" onClick={() => openGlossary("tenek")}>Tének</button>
                    </NavigationClose>
                }
                <div className="glossary">
                    <GlosarioEspanol/>
                </div>
                </div>
                <Footer/>
            </>
        )
    }
    else if (language === "nahuatl") {
        return (
            <>
                <Navbar/>
                <button class="btn-open" onClick={openTab}>☰</button>
                <div className="container">
                { close === 0 ?
                    <NavigationOpen id="sidetab" className="tab">
                        <a href="javascript:void(0)" class="btn-close" onClick={closeTab}>×</a>
                        <button className="tablinks" onClick={() => openGlossary("chol")} id="default">Ch'ol</button>
                        <button className="tablinks" onClick={() => openGlossary("espanol")}>Español</button>
                        <button className="tablinks" onClick={() => openGlossary("nahuatl")}>Náhuatl</button>
                        <button className="tablinks" onClick={() => openGlossary("popoluca")}>Popoluca</button>
                        <button className="tablinks" onClick={() => openGlossary("tenek")}>Tének</button>
                    </NavigationOpen>
                    :
                    <NavigationClose id="sidetab" className="tab">
                        <a href="javascript:void(0)" class="btn-close" onClick={closeTab}>×</a>
                        <button className="tablinks" onClick={() => openGlossary("chol")} id="default">Ch'ol</button>
                        <button className="tablinks" onClick={() => openGlossary("espanol")}>Español</button>
                        <button className="tablinks" style={{backgroundColor: "white", color:"#E87722"}}  onClick={() => openGlossary("nahuatl")}>Náhuatl</button>
                        <button className="tablinks" onClick={() => openGlossary("popoluca")}>Popoluca</button>
                        <button className="tablinks" onClick={() => openGlossary("tenek")}>Tének</button>
                    </NavigationClose>
                }
                <div className="glossary">
                    <GlosarioNahuatl/>
                </div>
                </div>
                <Footer/>
            </>
        )
    }
    else if (language === "popoluca") {
        return (
            <>
                <Navbar/>
                <button class="btn-open" onClick={openTab}>☰</button>
                <div className="container">
                { close === 0 ?
                    <NavigationOpen id="sidetab" className="tab">
                        <a href="javascript:void(0)" class="btn-close" onClick={closeTab}>×</a>
                        <button className="tablinks" onClick={() => openGlossary("chol")} id="default">Ch'ol</button>
                        <button className="tablinks" onClick={() => openGlossary("espanol")}>Español</button>
                        <button className="tablinks" onClick={() => openGlossary("nahuatl")}>Náhuatl</button>
                        <button className="tablinks" onClick={() => openGlossary("popoluca")}>Popoluca</button>
                        <button className="tablinks" onClick={() => openGlossary("tenek")}>Tének</button>
                    </NavigationOpen>
                    :
                    <NavigationClose id="sidetab" className="tab">
                        <a href="javascript:void(0)" class="btn-close" onClick={closeTab}>×</a>
                        <button className="tablinks" onClick={() => openGlossary("chol")} id="default">Ch'ol</button>
                        <button className="tablinks" onClick={() => openGlossary("espanol")}>Español</button>
                        <button className="tablinks" onClick={() => openGlossary("nahuatl")}>Náhuatl</button>
                        <button className="tablinks" style={{backgroundColor: "white", color:"#E87722"}}  onClick={() => openGlossary("popoluca")}>Popoluca</button>
                        <button className="tablinks" onClick={() => openGlossary("tenek")}>Tének</button>
                    </NavigationClose>
                }
                <div className="glossary">
                    <GlosarioPopoluca/>
                </div>
                </div>
                <Footer/>
            </>
        )
    }
    else if (language === "tenek") {
        return (
            <>
                <Navbar/>
                <button class="btn-open" onClick={openTab}>☰</button>
                <div className="container">
                { close === 0 ?
                    <NavigationOpen id="sidetab" className="tab">
                        <a href="javascript:void(0)" class="btn-close" onClick={closeTab}>×</a>
                        <button className="tablinks" onClick={() => openGlossary("chol")} id="default">Ch'ol</button>
                        <button className="tablinks" onClick={() => openGlossary("espanol")}>Español</button>
                        <button className="tablinks" onClick={() => openGlossary("nahuatl")}>Náhuatl</button>
                        <button className="tablinks" onClick={() => openGlossary("popoluca")}>Popoluca</button>
                        <button className="tablinks" onClick={() => openGlossary("tenek")}>Tének</button>
                    </NavigationOpen>
                    :
                    <NavigationClose id="sidetab" className="tab">
                        <a href="javascript:void(0)" class="btn-close" onClick={closeTab}>×</a>
                        <button className="tablinks" onClick={() => openGlossary("chol")} id="default">Ch'ol</button>
                        <button className="tablinks" onClick={() => openGlossary("espanol")}>Español</button>
                        <button className="tablinks" onClick={() => openGlossary("nahuatl")}>Náhuatl</button>
                        <button className="tablinks" onClick={() => openGlossary("popoluca")}>Popoluca</button>
                        <button className="tablinks" style={{backgroundColor: "white", color:"#E87722"}}  onClick={() => openGlossary("tenek")}>Tének</button>
                    </NavigationClose>
                }
                <div className="glossary">
                    <GlosarioTenek/>
                </div>
                </div>
                <Footer/>
            </>
        )
    }

    
}

export default Glosario