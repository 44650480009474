import Chol from '../assets/hero_chol.png'
import Chontal from '../assets/hero_chontal.png'
import Español from '../assets/hero_español.png'
import Mixteco from '../assets/hero_mixteco.png'
import Nahuatl from '../assets/hero_nahuatl.png'
import Popoluca from '../assets/hero_popoluca.png'
import Tenek from '../assets/hero_tenek.png'

export default [
    {
        title: 'Chol',
        description: 'Ciclo del agua en Chol',
        urls: Chol,
    },
    {
        title: 'Chontal',
        description: 'Ciclo del agua en Chontal',
        urls: Chontal,
    },
    {
        title: 'Español',
        description: 'Ciclo del agua en Español',
        urls: Español,
    },
    {
        title: 'Mixteco',
        description: 'Ciclo del agua en Mixteco',
        urls: Mixteco,
    },
    {
        title: 'Nahuatl',
        description: 'Ciclo del agua en Nahuatl',
        urls: Nahuatl,
    },
    {
        title: 'Popoluca',
        description: 'Ciclo del agua en Popoluca',
        urls: Popoluca,
    },
    {
        title: 'Tenek',
        description: 'Ciclo del agua en Tenek',
        urls: Tenek,
    }
]