import React from 'react'

const GlosarioTenek = () => {
  return (
    <section id="tenek" className="tabcontent">
            <h2>Glosario Tének</h2>
            <ul className="glossary-list">
                <li>
                    <p><span className="glossary-item">In belil an ja’:</span> antsan u awil ki bijiy axi u wat’el k’al an ja’, kom axé’ ets’ey u ejel al patal an xe’tsintaláb, al an ik’, tin eblím an tsabál, al an lejem ani tin alam an tsabál. Axé’ pel jún i kwechodh bel kom yab in kwa’al in tujtal mani in Taltal. U awil ki uluw abal an ja’ xi in uts’a’chik an biyál olmecas jayetse an ja’ axi xo’ wawá’ i uts’ál.</p>
                </li>
                <li>
                    <p><span className="glossary-item">Temperatura:</span> axé’ pel jún i lejbaxtaláb, in lejbayál in yanél an ejláts an molécula xi in kwa’al jún jant’oj. An temperatura u lejbayáb k’al an lejab xi in bij termómetro. An lejab axi lej exladh jats an Celcius (C). Ti tsabál ju’taj yab t’ekat (in bijiyalchik an nok’ “al nivel del mar), an ja’ u paxk’unal ti 100 C. ani u wenk’onal ti tsam ti 0 C.</p>
                </li>
                <li>
                    <p><span className="glossary-item">Condensación:</span> axé’ játs tam an ja’ xi wenk’onenek ti bijol u wenk’onal juníl ti ja’. Tama n bijol u k’adhíl <strong>tin eblím an tsabál</strong>, u tsambél ani talbél u wenk’onal ti ja’ xi k’ijax, ejtilits i tsakam jel o i pojodh tsam, axé’ játs an tokow.</p>
                </li>
                <li>
                    <p><span className="glossary-item">Precipitación:</span> antsan bijidh tam an mapul in walkál an ja’ abal ka ulits an áb, an tsam ani an pojodh tsam. Antsan an ja’ u wichél ti tsabál. An ja’ lej ets’ey u ulel ejtíl i ab.</p>
                </li>
                <li>
                    <p><span className="glossary-item">Transpiración:</span> jats tam u bijk’onal an ja’ axi wa’ats tin xekél an ts’ojolchik. An ja’ u wat’el tin ibíl an ts’ojolchik ani u kalel al i tsakam jolchik (tekedh t’ijax) axi in kwa’alchik tin kuxchik an ts’ojolchik ju’taj u wenk’onalchik an ja’ ti bijol ani u walkanal ti xe’ets.</p>
                </li>
                <li>
                    <p><span className="glossary-item">Infiltración:</span> an ja’ u pa’íl abal allá, in tujchal eblím ti tsabál taliy u wat’el ti anam ani ti t’ujub axi we’ jabachik in iniktal. An ja’ axi chiyadh k’al an áb u otsel tin iniktal an t’ujub ani al an anam.</p>
                </li>
                <li>
                    <p><span className="glossary-item">Dhikaxtaláb:</span> axé’ játs tam an ja’ axi u wat’el ti tsabál in wat’enchal an anam ani u wat’el tin alam an tsabál. In yanél an ja’ axi in wat’enchal an tsabál ani axi u wat’el tin alam pilchik al patal an tsabál, axé’ kom wa’ats i anam axi yab in jilál ka wat’ey an ja’, aniyej max lej badhich an tsabál o max wa’ats lej yan i ts’ojól.</p>
                </li>
                <li>
                    <p><span className="glossary-item">Escorrentía:</span> játs an ja’ axi u wat’el tin alam an tsabál, axé’ pel an ja’ xi u ulel k’al an áb, po aniyej játs an xo’kadh tsam (axi u pa’íl ma ti pakdha’ ts’enchik). Axé’ xi já’ játs axi yab bijk’on ani jayej yab wat’ey tin ál an tsabál.</p>
                    <br/>
                    <p><span className="glossary-item">Escorrentía superficial:</span> játs an já’ axi ulnek ani u wat’el tin alam an
                        tsabál kinadh k’al in tsapík an tsabál, yab u wat’el tin alam an tsabál.</p>
                </li>
                <li>
                    <p><span className="glossary-item">Ja’ axi wa’ats tin ál an tsabál:</span> ja’ axi in wat’enchal an tsabál ani wa’ats al in ok’ox pejách an tsabál. U awil ki ela’ al an pilchik pejách an tsabál axi yab in jilál ka wat’ey an ja’ axé’chik i bijiyáb “acuíferos”, in le’ kin uluw abal in kwa’al i ja’. An ja’ xi wa’ats tin ál an tsabál pel jún i eyendhanél axi tekedh exbadh abal ka awits ka t’ojonchik an atiklabchik ani abal i xe’ets yab ka odhey.</p>
                </li>
                <li>
                    <p><span className="glossary-item">Bijk’ontaláb:</span> antsan bijiyáb tam an ja’ xi k’ijax u bijk’onal. An bijk’ontaláb játs tam an k’ijax ja’ axi wa’ats ti lejem u wenk’onal ti bijol ani u k’adhíl ti mapul.</p>
                </li>
                <li>
                    <p><span className="glossary-item">Estados de la materia:</span> játs jant’ini’ u awil ki tsu’uw axi wa’ats ti tonídh xi tsapik (duro), xi k’ijax, xi bijol ani xi bijidh plasma. Axé’ pilchik in k’ak’ém o in tsamáy. In mólecula axi tsapikchik yabchik u lej ejel (yabchik walkadh), poa xi k’ijax ani xi bijol jajchik in kwa’alchik walkadh ka ejláts. Tam ka k’ak’ey lej u awil ka wat’ey an tse’chíl axi bijiyáb plasma</p>
                </li>
                <li>
                    <p><span className="glossary-item">Dheyab ja’:</span> axé’ wa’ats axi tsi’ik ani axi ts’apuw. An pulik lejem in kwa’al tekedh yan i ja’ axi ts’apuw. An ja’ axi tsi’ik wa’ats ti tsamadh tsabál, ti ja’ axi u wat’el tin alam an tsabál, al an itse’ ani an ja’ xi tolkodh ti tsabál.</p>
                </li>
            </ul>
        </section>
  )
}

export default GlosarioTenek