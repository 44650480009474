import { Grid } from '@mui/material';
import styled from 'styled-components';
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import infograma from '../../assets/infografias_png/Infograma_Espanol.png'
import audioChol1 from '../../assets/audios/Espanol/Infograma_espanol_1.mp3'
import audioChol2 from '../../assets/audios/Espanol/Infograma_espanol_2.mp3'
import audioChol3 from '../../assets/audios/Espanol/Infograma_espanol_3.mp3'
import audioChol4 from '../../assets/audios/Espanol/Infograma_espanol_4.mp3'
import audioChol5 from '../../assets/audios/Espanol/Infograma_espanol_5.mp3'
import audioChol6 from '../../assets/audios/Espanol/Infograma_espanol_6.mp3'
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';

const ImageInfograma = styled.img`
  width: 90%;
  @media screen and (max-width: 575px){
    width: 100%;
  }
`

const Box1 = styled.div`
  position: absolute;
  margin-left: 73%;
  margin-top: 15%;
  display: flex;
  justify-content: center;
`
const Box2 = styled.div`
  position: absolute;
  margin-left: 38%;
  margin-top: 25%;
  display: flex;
  justify-content: center;
`
const Box3 = styled.div`
  position: absolute;
  margin-left: 55%;
  margin-top: 41%;
  display: flex;
  justify-content: center;
`
const Box4 = styled.div`
  position: absolute;
  margin-left: 48%;
  margin-top: 64%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 576px){
    margin-left: 48%;
    margin-top: 67%;
  }
`
const Box5 = styled.div`
  position: absolute;
  margin-left: 17%;
  margin-top: 97%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 576px){
    margin-left: 5%;
    margin-top: 103%;
  }
`
const Box6 = styled.div`
  position: absolute;
  margin-left: 36%;
  margin-top: 119%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 576px){
    margin-left: 36%;
  margin-top: 128%;
  }
`

const PlayButton = styled.button`
  margin: 0;
  padding: 0;
  background-color: transparent;
  border-color: transparent;
`
const PauseButton = styled.button`
  margin: 0;
  padding: 0;
  background-color: transparent;
  border-color: transparent;
`



const audio1 = new Audio(audioChol1);
const audio2 = new Audio(audioChol2);
const audio3 = new Audio(audioChol3);
const audio4 = new Audio(audioChol4);
const audio5 = new Audio(audioChol5);
const audio6 = new Audio(audioChol6);

const InfografiaChol = () => {

  function playAudio1(){
    audio1.play();
  }

  function pauseAudio1(){
    audio1.pause();
  }
  function playAudio2(){
    audio2.play();
  }

  function pauseAudio2(){
    audio2.pause();
  }
  function playAudio3(){
    audio3.play();
  }

  function pauseAudio3(){
    audio3.pause();
  }
  function playAudio4(){
    audio4.play();
  }

  function pauseAudio4(){
    audio4.pause();
  }
  function playAudio5(){
    audio5.play();
  }

  function pauseAudio5(){
    audio5.pause();
  }
  function playAudio6(){
    audio6.play();
  }

  function pauseAudio6(){
    audio6.pause();
  }

  return (
    <>
      <Navbar/>
        <Grid 
        sx={{
          backgroundColor: "#084d88",
        }}
        container>
          <Grid   style={{textAlign:"center", margin:" 80px auto 20px auto", position:"relative"}}>
            <Box1>
            <PlayButton onClick={() => {playAudio1()}}>
              <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
            </PlayButton>
            <PauseButton onClick={() => {pauseAudio1()}}>
              <PauseCircleFilledIcon style={{color: "orange"}}/>
            </PauseButton>
            </Box1>
            <Box2>
            <PlayButton onClick={() => {playAudio2()}}>
              <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
            </PlayButton>
            <PauseButton onClick={() => {pauseAudio2()}}>
              <PauseCircleFilledIcon style={{color: "orange"}}/>
            </PauseButton>
            </Box2>
            <Box3>
            <PlayButton onClick={() => {playAudio3()}}>
              <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
            </PlayButton>
            <PauseButton onClick={() => {pauseAudio3()}}>
              <PauseCircleFilledIcon style={{color: "orange"}}/>
            </PauseButton>
            </Box3>
            <Box4>
            <PlayButton onClick={() => {playAudio4()}}>
              <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
            </PlayButton>
            <PauseButton onClick={() => {pauseAudio4()}}>
              <PauseCircleFilledIcon style={{color: "orange"}}/>
            </PauseButton>
            </Box4>
            <Box5>
            <PlayButton onClick={() => {playAudio5()}}>
              <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
            </PlayButton>
            <PauseButton onClick={() => {pauseAudio5()}}>
              <PauseCircleFilledIcon style={{color: "orange"}}/>
            </PauseButton>
            </Box5>
            <Box6>
            <PlayButton onClick={() => {playAudio6()}}>
              <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
            </PlayButton>
            <PauseButton onClick={() => {pauseAudio6()}}>
              <PauseCircleFilledIcon style={{color: "orange"}}/>
            </PauseButton>
            </Box6>
            <ImageInfograma src={infograma} alt="infograma chol"/>
          </Grid>
        </Grid>
      <Footer/>
    </>
  )
}

export default InfografiaChol