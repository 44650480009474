import React from 'react'
import './glosario.css'

const GlosarioNahuatl = () => {
  return (
    <section id="nahuatl" className="tabcontent">
            <h2>Glosario Náhuatl</h2>
            <ul className="glossary-list">
                <li>
                    <p><span className="glossary-item">Atl iyawaltsi:</span> Ni atl iyawaltsi eli ahakachika atl iolinilis ipan Tlaltipaktli ika ilwikatl, tlalli iixkoh, weyatl wan tlatsintla tlalchi. Ni ohtli kiseliah itokah <strong>yalwaltsi</strong> pampa iseltih moyankwilia, axkema pewa wan axkema tlami.</p>
                    <br/>
                    <p className="text-small">Yeka, weli moihtos atl tlen kinonihkeh toweytatawah olmekameh wan tlen namah tohwanti tikonih sanse eli.</p>
                </li>
                <li>
                    <p><span className="glossary-item">Tlatotonkayotl:</span> Yani kitlamachiwa kenihkatsa mooliniah se tlakayotl ipiltlapankatsitsih. Tlatotonkayotl motlamachiwa ika tepostli titokachtiah termómetro, itlamachiwal katli achi moixmatih itokah Celsius (C). Kampa onka weyatl atl tlamoloni ika 100 C wan mochiwa tetik ika 0 C.</p>
                </li>
                <li>
                    <p><span className="glossary-item">Kema atl eli ahatsitsi wan mokwepa atik:</span> ohtli kampa atl mopatla ahatsitsi wan mochiwa atik. Kema apohtli tlehkowa ipan ilwikatl, mosesewia wan <strong>mokwepa atik</strong>, yani kichiwa miak tlachipintli noso setl piltolontsitsi, yani eli mixtli.</p>
                </li>
                <li>
                    <p><span className="glossary-item">Tlaawetsi:</span> wetsi atl kisah kampa mixtli keh kiawilistli, setl tlen axmiak tetik noso setl piltolontsitsih. Yani eli ohtli ika atl mokwepa nika Tlalli. Achi moixnextia <strong>wetsi</strong> keh kiawilistli.</p>
                </li>
                <li>
                    <p><span className="glossary-item">Mitonilistli:</span> Ni apohtli kisah kampa ixiwiyoh xiwimeh. Ni atl pewa kisah kampa inelwayoh panowa kampa xiwiyoh ipilkoyonkah iika mokawa, nompona mopatla apohtli wan motlehkowa ilwikatl.</p>
                </li>
                <li>
                    <p><span className="glossary-item">Mokalaki iihtiko tlalchi:</span> atl iolinilis yawi tlatsintla pewa iixko Tlalli wan asih tlalchi wan tetetsitsih ipiltlakoyonkah. Kampa weli tlen ni Tlaltipaktli, se achi atl tlen <strong>wetsi mokalaki iihtiko</strong> tlalchi wan tetetsitsih.</p>
                </li>
                <li>
                    <p><span className="glossary-item">Kenihkatsa moxexelowa tetetsitsih, tlasolli tlen atik atl kema panowa tlalchi:</span> Mochiwa kema atl tlen asih tlalchi, mokalakih wan yawi tatsintla tlalchi. Atl tlen mokalakiah wan tlen mokawa iixko (anehnentli) mochiwas tlah tlalchi kikawas ma panos, tlah moixnekwiltlaliah wan kenihkatsa onkas xiwitl.</p>
                </li>
                <li>
                    <p><span className="glossary-item">Anehnentli:</span> Ni atl tlen walah ika kiawilistli noso tlen setl katli moxitiniah wan yawi tlalchi iixko, teipa kema motlamia apohtli iohtli wan mokalakih ipan tlalchi.</p>
                    <br/>
                    <p><span className="glossary-item">Iixko anehnentli:</span>eli atl tlen yawi iixko tlalli wan amo mokalakih ipan tlalchi.</p>
                </li>
                <li>
                    <p><span className="glossary-item">Atl tlen yawi tlatsintla tlalchi:</span> eli <strong>atl tlen mokalaki</strong> ipan tlalchi wan mokawa iixko axwejka mokalaki wan axkikawilia ma kisah, ya ni moixmatih keh ameyalmeh. Ni <strong>atl tlen yawi tlatsintla tlalchi</strong> eli se weyi tlamantli ma masewalmeh intekitiseh wan nohkia mowehkawas yolistli.</p>
                </li>
                <li>
                    <p><span className="glossary-item">Kenihkatsa mochiwa apohtli:</span> eli ohtli kampa atl mopatla tlen atik mochiwa ahatsitsi. Ni kenihkatsa mochiwa apohtli eli ohtli kampa atl tlen atik tlen onka kampa weyatl yawi kampa ilwikatl keh apohtli.</p>
                </li>
                <li>
                    <p><span className="glossary-item">Kenihkatsa moixnextiah tlamantli:</span> elih tetik, atik, ahatsitsi wan plasma. Motokachtiah ika tlamantli itlatotonka: tlen tetik tlawelowih ma moolinis ipiltlapankatsitsih, tlen atik wan ahatsitsi, achi mooliniah ipiltlapankatsitsih. Kema achi tlatotona, moasih ipan nawi tlamantli iinexkayoh: atoltik (plasma eli se ahatsistsi tlen nochipa moolinia ipiltlapankatsitsih).</p>
                </li>
                <li>
                    <p><span className="glossary-item">Tlaloko kampa onka atl:</span> onka tsopelik atl wan nohkia poyak. Weyahmeh kinpiah tlawelmiak poyak atl yani eli tlen achi miak. Tlaloko kampa onka achi weyi tsopelik atl eli weyitetl wan tetik, atl tlen tlatsintla tlalchi wan atoyahmeh.</p>
                </li>
            </ul>
        </section>
  )
}

export default GlosarioNahuatl