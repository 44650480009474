import React from 'react'
import styled from 'styled-components';
import audioChol1 from '../assets/audios/Chol_glosario/Glosario-chol-1.mp3'
import audioChol2 from '../assets/audios/Chol_glosario/Glosario-chol-2.mp3'
import audioChol3 from '../assets/audios/Chol_glosario/Glosario-chol-3.mp3'
import audioChol4 from '../assets/audios/Chol_glosario/Glosario-chol-4.mp3'
import audioChol5 from '../assets/audios/Chol_glosario/Glosario-chol-5.mp3'
import audioChol6 from '../assets/audios/Chol_glosario/Glosario-chol-6.mp3'
import audioChol7 from '../assets/audios/Chol_glosario/Glosario-chol-7.mp3'
import audioChol8 from '../assets/audios/Chol_glosario/Glosario-chol-8.mp3'
import audioChol9 from '../assets/audios/Chol_glosario/Glosario-chol-9.mp3'
import audioChol10 from '../assets/audios/Chol_glosario/Glosario-chol-10.mp3'
import audioChol11 from '../assets/audios/Chol_glosario/Glosario-chol-11.mp3'
import audioChol12 from '../assets/audios/Chol_glosario/Glosario-chol-12.mp3'
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import './glosario.css'

const audio1 = new Audio(audioChol1);
const audio2 = new Audio(audioChol2);
const audio3 = new Audio(audioChol3);
const audio4 = new Audio(audioChol4);
const audio5 = new Audio(audioChol5);
const audio6 = new Audio(audioChol6);
const audio7 = new Audio(audioChol7);
const audio8 = new Audio(audioChol8);
const audio9 = new Audio(audioChol9);
const audio10 = new Audio(audioChol10);
const audio11 = new Audio(audioChol11);
const audio12 = new Audio(audioChol12);

const PlayButton = styled.button`
  margin: 0;
  padding: 0;
  background-color: transparent;
  border-color: transparent;
`
const PauseButton = styled.button`
  margin: 0;
  padding: 0;
  background-color: transparent;
  border-color: transparent;
`

const GlosarioChol = () => {

    function playAudio1(){
        audio1.play();
      }
    
      function pauseAudio1(){
        audio1.pause();
      }
      function playAudio2(){
        audio2.play();
      }
    
      function pauseAudio2(){
        audio2.pause();
      }
      function playAudio3(){
        audio3.play();
      }
    
      function pauseAudio3(){
        audio3.pause();
      }
      function playAudio4(){
        audio4.play();
      }
    
      function pauseAudio4(){
        audio4.pause();
      }
      function playAudio5(){
        audio5.play();
      }
    
      function pauseAudio5(){
        audio5.pause();
      }
      function playAudio6(){
        audio6.play();
      }
    
      function pauseAudio6(){
        audio6.pause();
      }

      function playAudio7(){
        audio7.play();
      }
    
      function pauseAudio7(){
        audio7.pause();
      }
      function playAudio8(){
        audio8.play();
      }
    
      function pauseAudio8(){
        audio8.pause();
      }
      function playAudio9(){
        audio9.play();
      }
    
      function pauseAudio9(){
        audio9.pause();
      }
      function playAudio10(){
        audio10.play();
      }
    
      function pauseAudio10(){
        audio10.pause();
      }
      function playAudio11(){
        audio11.play();
      }
    
      function pauseAudio11(){
        audio11.pause();
      }
      function playAudio12(){
        audio12.play();
      }
    
      function pauseAudio12(){
        audio12.pause();
      }


  return (
    <section id="chol" className="tabcontent">
            <h2>Glosario Ch'ol</h2>
            <ul className="glossary-list">
                <li>
                    <div style={{position:"absolute", top:"20px"}}>
                        <PlayButton onClick={() => {playAudio1()}}>
                            <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
                        </PlayButton>
                        <PauseButton onClick={() => {pauseAudio1()}}>
                            <PauseCircleFilledIcon style={{color: "orange"}}/>
                        </PauseButton>
                    </div>
                    <p><span className="glossary-item">Bajche’ mi ibel wil k’extyäyel ibäj jiñi ja’:</span> jiñäch bajche’ mi ik’extyäyel jiñi ja’ ila tyi pañämil che’bä mi ixäñ majlel tyi pañchañ, tyi lum, tyi ñajb yik’oty tyi ye’bal lum. Ili ik’extyäyel ja’ ik’aba’ <strong>ciclo</strong> kome che’äch mi ibel xäñ majlel, ma’añik ityejchi’bal yik’oty ijili’bal.</p>
                    <br/>
                    <p className="text-small"> Jiñ cha’añ mi imejlel lakäl cha’añ jiñi ja’ tsa’bä ijapäyob x-olmekajob jiñäch woli lakcha’ jap wäle.</p>
                </li>
                <li>
                  <div style={{position:"absolute", top:"20px"}}>
                      <PlayButton onClick={() => {playAudio2()}}>
                          <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
                      </PlayButton>
                      <PauseButton onClick={() => {pauseAudio2()}}>
                          <PauseCircleFilledIcon style={{color: "orange"}}/>
                      </PauseButton>
                    </div>
                    <p><span className="glossary-item">Ik’äk’älel:</span> Jiñ che’ mi lakäl o mi lajk’el jayp’ej k’amel mi iñijkañ ibäj imoleculastyak icha’añ bäk’tyaläl o añ chu’bä. Jiñi ik’äk’alel mi lakp’is yik’oty termometrotyak, jiñäch mi ik’äñob ip’isol jiñi grados Celsius (C). Iñumelel jiñi ñajp, jiñi ja’ mi ilojkañ tyi 100 C yik’oty mi itsästs-añ tyi 0 C.</p>
                </li>
                <li>
                    <div style={{position:"absolute", top:"20px"}}>
                      <PlayButton onClick={() => {playAudio3()}}>
                          <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
                      </PlayButton>
                      <PauseButton onClick={() => {pauseAudio3()}}>
                          <PauseCircleFilledIcon style={{color: "orange"}}/>
                      </PauseButton>
                    </div>
                    <p><span class="glossary-item"> I yowix mi isujtyel tyi ja’:</span> jiñäch che’bä jiñi ja’ mi ik’extyañ iyilal, ja’ ja’tyik mi isujtyel. Jiñ che’ iyowixlel ja’ mi iletsel tyi pañchañ, mi itsäñañ che’ jiñi mi itsäts-añ, mi isujtyel tyi tyokal.</p>
                </li>
                <li>
                    <div style={{position:"absolute", top:"20px"}}>
                      <PlayButton onClick={() => {playAudio4()}}>
                          <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
                      </PlayButton>
                      <PauseButton onClick={() => {pauseAudio4()}}>
                          <PauseCircleFilledIcon style={{color: "orange"}}/>
                      </PauseButton>
                    </div>
                    <p><span className="glossary-item">Ja’al:</span> Jiñ che’ muk’ix ichok ju’bel ja’ jiñi tyokal, ja’bänieve, nieve o tyuñija’. Ñuk ik’ajñibal kome che’äch mi icha’    sujtyel jiñi ja’ ila tyi pañumil. Yoñlel jiñi ja’ che’äch mi iju’bel tyi ja’al.</p>
                </li>
                <li>
                    <div style={{position:"absolute", top:"20px"}}>
                      <PlayButton onClick={() => {playAudio5()}}>
                          <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
                      </PlayButton>
                      <PauseButton onClick={() => {pauseAudio5()}}>
                          <PauseCircleFilledIcon style={{color: "orange"}}/>
                      </PauseButton>
                    </div>
                    <p><span className="glossary-item">Bu’lichäl:</span> jiñ iyowixtyak iyopol tye’tyak. Jiñi ja’ mi ipäy majlel k’älä tyi iwi’ jiñtyo tyi iyopoltyak, ya’ baki mi isujtyel tyi yowix muk’bä imajlel tyi pañchañ.</p>
                </li>
                <li>
                   <div style={{position:"absolute", top:"20px"}}>
                      <PlayButton onClick={() => {playAudio6()}}>
                          <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
                      </PlayButton>
                      <PauseButton onClick={() => {pauseAudio6()}}>
                          <PauseCircleFilledIcon style={{color: "orange"}}/>
                      </PauseButton>
                    </div>
                    <p><span className="glossary-item"></span> Che’äch bajche’ mi iyochel ju’bel ja’ añbä tyi pam lum k’älä tyi ye’bal o ya’ baki tyokoltyak xajlel. Bakijach che’ mi iyujtyel ila tyi pañumil, jiñche’ mi iyujtyel ja’al mi iyochel majlel tyi lum yik’oty tyi xajleltyak.</p>
                </li>
                <li>
                  <div style={{position:"absolute", top:"20px"}}>
                      <PlayButton onClick={() => {playAudio7()}}>
                          <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
                      </PlayButton>
                      <PauseButton onClick={() => {pauseAudio7()}}>
                          <PauseCircleFilledIcon style={{color: "orange"}}/>
                      </PauseButton>
                    </div>
                    <p><span className="glossary-item">Its’u ochel:</span> jiñ mi iyujtyel che’ jiñi ja’ mi ityaj jiñi lum, mi its’u ochel muk’ix isujtyel tyi ja’ tyi ye’bal lum. Bajche’ k’amel ja’ mi iyochel majlel tyi ye’bal lum yik’oty muk’bä iläm kälel tyi kaj ja’al jiñäch tsikil bajche’ yilal jiñi lum, mi kaläx wits o chukityak tye’el añ icha’añ.</p>
                </li>
                <li>
                    <div style={{position:"absolute", top:"20px"}}>
                        <PlayButton onClick={() => {playAudio8()}}>
                            <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
                        </PlayButton>
                        <PauseButton onClick={() => {pauseAudio8()}}>
                            <PauseCircleFilledIcon style={{color: "orange"}}/>
                        </PauseButton>
                    </div>
                    <p><span className="glossary-item"></span> Jiñäch jiñi ja’ muk’bä iläm kälel tyi kaj ja’al o che’ mi k’uñ-añ jiñi ja’ o tyuñija’nieve añbä tyi pam lum, che’ ma’añix mi imejlel tyi yowix o che’ ma’añix mi its’u ochel jiñi lum.</p>
                    <br/>
                    <p>Jiñäch jiñi ja’ muk’bä iläm käy ja’al, ma’añix mi its’u’ ochel jiñi lum.</p>
                </li>
                <li>
                  <div style={{position:"absolute", top:"20px"}}>
                        <PlayButton onClick={() => {playAudio9()}}>
                            <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
                        </PlayButton>
                        <PauseButton onClick={() => {pauseAudio9()}}>
                            <PauseCircleFilledIcon style={{color: "orange"}}/>
                        </PauseButton>
                    </div>
                    <p><span className="glossary-item">ja’ tyi ye’bal lum:</span> Jiñäch jiñi ja’ muk’bä its’u’ ochel jiñi lum ya’ächbä añ tyi ipam lum. Mi imejlel lakläm tyaje’tyak ja’ ya baki weñ añ xajlel ik’aba jiñäch <strong>acuíferos</strong>.</p>
                    <br/>
                    <p className="text-small">Jiñi ja’ tyi ye’bal lum jiñäch ñukbä lakcha’añ yik’oty pejtyelel ikuxtyälel pañämil.</p>
                </li>
                <li>
                    <div style={{position:"absolute", top:"20px"}}>
                      <PlayButton onClick={() => {playAudio10()}}>
                          <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
                      </PlayButton>
                      <PauseButton onClick={() => {pauseAudio10()}}>
                          <PauseCircleFilledIcon style={{color: "orange"}}/>
                      </PauseButton>
                    </div>
                    <p><span className="glossary-item">Iyowixlel ja’:</span> Jiñ che’ mi ik’extyäyel iyilal jiñi ja’ che’ lämäl mi ilok’el iyowix. Iyowixlel ja’ jiñäch bajche’ jiñi ja’ja’tyikbä ja’ añbä tyi ñajbtyak mi ik’otyel tyi pañchañ, che’ iyowixix.</p>
                </li>
                <li>
                  <div style={{position:"absolute", top:"20px"}}>
                        <PlayButton onClick={() => {playAudio11()}}>
                            <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
                        </PlayButton>
                        <PauseButton onClick={() => {pauseAudio11()}}>
                            <PauseCircleFilledIcon style={{color: "orange"}}/>
                        </PauseButton>
                    </div>
                    <p><span className="glossary-item">Iyilal chubu’tyak:</span> Jiñäch che’ tsätstyak (sólido), ja’ja’tyik (liquido), buts’iñtyik (gaseoso) yik’oty plasma iyilal. Mi ipäs ibäj che’ bajche’ k’amel ik’äk’älel: Jiñche’ tsätstyak (sólido), ma’añik mi imejlel iweñ ñijkañ ibäj imolekulatyak , jiñche’ ja’ja’tyik (liquido) yik’oty buts’iñtyik (gaseoso), weñ mi iñijkañob ibäj. Jiñche’ mi iweñ letsel ik’äk’älel mi ik’otyel tyi ichämpe’jlel iyilal jiñi chubu’tyak; jiñäch ik’aba’ plasma.</p>
                </li>
                <li>
                <div style={{position:"absolute", top:"20px"}}>
                        <PlayButton onClick={() => {playAudio12()}}>
                            <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
                        </PlayButton>
                        <PauseButton onClick={() => {pauseAudio12()}}>
                            <PauseCircleFilledIcon style={{color: "orange"}}/>
                        </PauseButton>
                    </div>
                    <p><span className="glossary-item">Iläm käytyäl ja’:</span>añtyak tsajbä ja’ yik’oty ats’ämtyikbä. Ya’ tyi ñajptyak añ weñ ka’bäl tsajbä tyi ats’am jiñi ja’. Ya’ baki añ tsajbä o sumukbä ja yä’äch tyi kolem ja’bä xajleltyak (glaciares), ja’ añbä tyi ye’bal lum, tyi pa’tyak o lämja’</p>
                </li>
            </ul>
        </section>

  )
}

export default GlosarioChol