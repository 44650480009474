import * as React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Link } from "react-router-dom"
import SMFLogo from "../Logo_SMF_Horizontal.png"

const LinkStyledInicio = styled(Link)`
    padding: 5px 35px;
    background-color: rgb(246, 190, 0);
    color: white;
    text-decoration: none;
    margin-right: 30px;
    border-radius: 15px;
`

const LinkStyledGlosario = styled(Link)`
    padding: 5px 35px;
    color: black;
    text-decoration: none;
    margin-right: 25px;
    &:hover{
        background-color: rgb(232, 119, 34);
        color: white;
        border-radius: 15px;
    }
`

function Navbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <LinkStyledGlosario  to="/" sx={{ color: 'black' }}>
                   Inicio
        </LinkStyledGlosario>
      </MenuItem>
      <MenuItem>
        <LinkStyledGlosario  to="/infografias" sx={{ color: 'black' }}>
                   Infografías
            </LinkStyledGlosario>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <LinkStyledGlosario  to="/glosario" sx={{ color: 'black' }}>
                   Glosario
            </LinkStyledGlosario>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="absolute" style={{background: "#F5F5F5"}}>
        <Toolbar>
          <Box alignItems="left" style={{marginLeft:"50px"}}>
                    <img src={SMFLogo} alt="logo_SMF" width="120px"/>
            </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <LinkStyledInicio  to="/" sx={{ color: 'black' }}>
                   Inicio
            </LinkStyledInicio>
            <LinkStyledGlosario  to="/infografias" sx={{ color: 'black' }}>
                   Infografias
            </LinkStyledGlosario>
            <LinkStyledGlosario to="/glosario" sx={{ color: 'black' }}>
                   Glosario
            </LinkStyledGlosario>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="black"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}

export default Navbar;