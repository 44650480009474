import { Grid } from '@mui/material';
import styled from 'styled-components';
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import infograma from '../../assets/infografias_png/Infograma_Chontal.png'

const ImageInfograma = styled.img`
  width: 90%;
  @media screen and (max-width: 575px){
    width: 100%;
  }
`

const InfografiaChontal = () => {

  return (
    <>
      <Navbar/>
        <Grid 
        sx={{
          backgroundColor: "#084d88",
        }}
        container>
          <Grid   style={{textAlign:"center", margin:" 80px auto 20px auto", position:"relative"}}>
            <ImageInfograma src={infograma} alt="infograma chol"/>
          </Grid>
        </Grid>
      <Footer/>
    </>
  )
}

export default InfografiaChontal