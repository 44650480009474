import React from 'react'
import Navbar from '../components/Navbar'
import Carousel from '../components/Carousel'
import Footer from '../components/Footer'
import Content from '../components/Content'
import styled from 'styled-components'
import ImagenPortada from '../assets/hero_español.png'

const StyledDivL = styled.div`
  @media screen and (max-width: 576px){
    display: none;
  }
`
const StyledDivSM = styled.div`
  margin-top: 72px;
  @media screen and (min-width: 576px){
    display: none;
  }
`

const Homepage = () => {
  return (
    <>  
        
        <Navbar/>
        <StyledDivL>
          <Carousel/>
        </StyledDivL>
        <StyledDivSM> 
          <img src={ImagenPortada} alt='portada' width="100%"/>
        </StyledDivSM>
        <Content/>
        <Footer/>
    </>
  )
}

export default Homepage