import React from 'react'
import audioEspanol1 from '../assets/audios/Espanol_glosario/Glosario-espanol-1.mp3'
import audioEspanol2 from '../assets/audios/Espanol_glosario/Glosario-espanol-2.mp3'
import audioEspanol3 from '../assets/audios/Espanol_glosario/Glosario-espanol-3.mp3'
import audioEspanol4 from '../assets/audios/Espanol_glosario/Glosario-espanol-4.mp3'
import audioEspanol5 from '../assets/audios/Espanol_glosario/Glosario-espanol-5.mp3'
import audioEspanol6 from '../assets/audios/Espanol_glosario/Glosario-espanol-6.mp3'
import audioEspanol7 from '../assets/audios/Espanol_glosario/Glosario-espanol-7.mp3'
import audioEspanol8 from '../assets/audios/Espanol_glosario/Glosario-espanol-8.mp3'
import audioEspanol9 from '../assets/audios/Espanol_glosario/Glosario-espanol-9.mp3'
import audioEspanol10 from '../assets/audios/Espanol_glosario/Glosario-espanol-10.mp3'
import audioEspanol11 from '../assets/audios/Espanol_glosario/Glosario-espanol-11.mp3'
import audioEspanol12 from '../assets/audios/Espanol_glosario/Glosario-espanol-12.mp3'
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import styled from 'styled-components'
import './glosario.css'

const audio1 = new Audio(audioEspanol1);
const audio2 = new Audio(audioEspanol2);
const audio3 = new Audio(audioEspanol3);
const audio4 = new Audio(audioEspanol4);
const audio5 = new Audio(audioEspanol5);
const audio6 = new Audio(audioEspanol6);
const audio7 = new Audio(audioEspanol7);
const audio8 = new Audio(audioEspanol8);
const audio9 = new Audio(audioEspanol9);
const audio10 = new Audio(audioEspanol10);
const audio11 = new Audio(audioEspanol11);
const audio12 = new Audio(audioEspanol12);

const PlayButton = styled.button`
  margin: 0;
  padding: 0;
  background-color: transparent;
  border-color: transparent;
`
const PauseButton = styled.button`
  margin: 0;
  padding: 0;
  background-color: transparent;
  border-color: transparent;
`

const GlosarioEspanol = () => {

    function playAudio1(){
        audio1.play();
      }
    
      function pauseAudio1(){
        audio1.pause();
      }
      function playAudio2(){
        audio2.play();
      }
    
      function pauseAudio2(){
        audio2.pause();
      }
      function playAudio3(){
        audio3.play();
      }
    
      function pauseAudio3(){
        audio3.pause();
      }
      function playAudio4(){
        audio4.play();
      }
    
      function pauseAudio4(){
        audio4.pause();
      }
      function playAudio5(){
        audio5.play();
      }
    
      function pauseAudio5(){
        audio5.pause();
      }
      function playAudio6(){
        audio6.play();
      }
    
      function pauseAudio6(){
        audio6.pause();
      }

      function playAudio7(){
        audio7.play();
      }
    
      function pauseAudio7(){
        audio7.pause();
      }
      function playAudio8(){
        audio8.play();
      }
    
      function pauseAudio8(){
        audio8.pause();
      }
      function playAudio9(){
        audio9.play();
      }
    
      function pauseAudio9(){
        audio9.pause();
      }
      function playAudio10(){
        audio10.play();
      }
    
      function pauseAudio10(){
        audio10.pause();
      }
      function playAudio11(){
        audio11.play();
      }
    
      function pauseAudio11(){
        audio11.pause();
      }
      function playAudio12(){
        audio12.play();
      }
    
      function pauseAudio12(){
        audio12.pause();
      }

  return (
    <section id="espanol" class="tabcontent">
            <h2>Glosario Español</h2>
            <ul class="glossary-list">
                <li>
                    <div style={{position:"absolute", top:"20px"}}>
                        <PlayButton onClick={() => {playAudio1()}}>
                            <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
                        </PlayButton>
                        <PauseButton onClick={() => {pauseAudio1()}}>
                            <PauseCircleFilledIcon style={{color: "orange"}}/>
                        </PauseButton>
                    </div>
                    <p><span class="glossary-item">Ciclo natural del agua:</span> Es el movimiento contínuo del agua en el planeta a través de la atmósfera, la superficie terrestre, el océano y el subsuelo. Este proceso recibe el nombre de <strong>ciclo</strong> debido a que se alimenta a sí mismo, no tiene ni un principio ni un fin.</p>
                    <br/>
                    <p class="text-small">Por esta razón, se puede decir que el agua que bebieron los olmecas y la que hoy bebemos nosotros es la misma.</p>
                </li>
                <li>
                <div style={{position:"absolute", top:"20px"}}>
                        <PlayButton onClick={() => {playAudio2()}}>
                            <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
                        </PlayButton>
                        <PauseButton onClick={() => {pauseAudio2()}}>
                            <PauseCircleFilledIcon style={{color: "orange"}}/>
                        </PauseButton>
                    </div>
                    <p><span class="glossary-item">Temperatura:</span> Es una medida de la cantidad de movimiento de las moléculas de un cuerpo. La temperatura se mide con los termómetros, siendo la escala más extendida la de grados Celsius (C). Al nivel del mar, el agua hierve a 100 C y se congela a 0 C.</p>
                </li>
                <li>
                <div style={{position:"absolute", top:"20px"}}>
                        <PlayButton onClick={() => {playAudio3()}}>
                            <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
                        </PlayButton>
                        <PauseButton onClick={() => {pauseAudio3()}}>
                            <PauseCircleFilledIcon style={{color: "orange"}}/>
                        </PauseButton>
                    </div>
                    <p><span class="glossary-item">Condensación:</span> Proceso mediante el cual el agua cambia de estado gaseoso a líquido. Cuando el vapor de agua sube por la atmósfera, se enfría y se <strong>condensa</strong>, formando cúmulos de gotitas o cristales de hielo, que son las nubes.</p>
                </li>
                <li>
                <div style={{position:"absolute", top:"20px"}}>
                        <PlayButton onClick={() => {playAudio4()}}>
                            <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
                        </PlayButton>
                        <PauseButton onClick={() => {pauseAudio4()}}>
                            <PauseCircleFilledIcon style={{color: "orange"}}/>
                        </PauseButton>
                    </div>
                    <p><span class="glossary-item">Precipitación:</span> Liberación de agua desde las nubes en forma de lluvia, aguanieve, nieve o granizo. Es el principal proceso por el cual el agua retorna a la Tierra. La mayor parte de la precipitación cae como lluvia.</p>
                </li>
                <li>
                <div style={{position:"absolute", top:"20px"}}>
                        <PlayButton onClick={() => {playAudio5()}}>
                            <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
                        </PlayButton>
                        <PauseButton onClick={() => {pauseAudio5()}}>
                            <PauseCircleFilledIcon style={{color: "orange"}}/>
                        </PauseButton>
                    </div>
                    <p><span class="glossary-item">Transpiración:</span> Evaporación del agua desde las hojas de las plantas. El agua es llevada desde las raíces hasta pequeños poros que se encuentran en la cara inferior de las hojas, donde se transforma en vapor y se libera a la atmósfera.</p>
                </li>
                <li>
                <div style={{position:"absolute", top:"20px"}}>
                        <PlayButton onClick={() => {playAudio6()}}>
                            <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
                        </PlayButton>
                        <PauseButton onClick={() => {pauseAudio6()}}>
                            <PauseCircleFilledIcon style={{color: "orange"}}/>
                        </PauseButton>
                    </div>
                    <p><span class="glossary-item">Infiltración:</span> El movimiento descendente del agua desde la superficie de la Tierra hacia el suelo o las rocas porosas. En cualquier parte del mundo, una porción del agua que cae como precipitación se infiltra hacia el suelo y las rocas.</p>
                </li>
                <li>
                <div style={{position:"absolute", top:"20px"}}>
                        <PlayButton onClick={() => {playAudio7()}}>
                            <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
                        </PlayButton>
                        <PauseButton onClick={() => {pauseAudio7()}}>
                            <PauseCircleFilledIcon style={{color: "orange"}}/>
                        </PauseButton>
                    </div>
                    <p><span class="glossary-item">Filtración:</span> Ocurre cuando el agua que alcanza el suelo, lo penetra y pasa a ser subterránea. La proporción de agua que se filtra y la que circula en superficie (escorrentía) dependen de la permeabilidad del subsuelo, de la pendiente y de la cobertura vegetal.</p>
                </li>
                <li>
                <div style={{position:"absolute", top:"20px"}}>
                        <PlayButton onClick={() => {playAudio8()}}>
                            <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
                        </PlayButton>
                        <PauseButton onClick={() => {pauseAudio8()}}>
                            <PauseCircleFilledIcon style={{color: "orange"}}/>
                        </PauseButton>
                    </div>
                    <p><span class="glossary-item">Escorrentía:</span> El flujo de agua procedente de las lluvias o deshielo de nieve que circula sobre la superficie del suelo, una vez que se supera la capacidad de <strong>evaporación</strong> y de <strong>infiltración</strong> de la misma.</p>
                    <br/>
                    <p><span class="glossary-item">Escorrentía superficial o directa:</span> es el agua precipitada que discurre sobre la superficie del terreno por la acción de la gravedad, sin infiltrarse en el suelo.</p>
                </li>
                <li>
                <div style={{position:"absolute", top:"20px"}}>
                        <PlayButton onClick={() => {playAudio9()}}>
                            <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
                        </PlayButton>
                        <PauseButton onClick={() => {pauseAudio9()}}>
                            <PauseCircleFilledIcon style={{color: "orange"}}/>
                        </PauseButton>
                    </div>
                    <p><span class="glossary-item">Agua subterránea:</span> Agua que se <strong>infiltra</strong> en el terreno y se sitúa a nivel superficial en la corteza terrestre. Suele encontrarse en formaciones geológicas impermeables llamadas acuíferos.</p>
                    <br/>
                    <p class="text-small">El agua subterránea es un recurso fundamental para la actividad humana y el mantenimiento de los ecosistemas.</p>
                </li>
                <li>
                <div style={{position:"absolute", top:"20px"}}>
                        <PlayButton onClick={() => {playAudio10()}}>
                            <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
                        </PlayButton>
                        <PauseButton onClick={() => {pauseAudio10()}}>
                            <PauseCircleFilledIcon style={{color: "orange"}}/>
                        </PauseButton>
                    </div>
                    <p><span class="glossary-item">Evaporación:</span> Proceso mediante el cual el agua cambia de estado líquido a gaseoso. La evaporación es el proceso por el cual el agua líquida de los océanos ingresa a la atmósfera, en forma de vapor.</p>
                </li>
                <li>
                <div style={{position:"absolute", top:"20px"}}>
                        <PlayButton onClick={() => {playAudio11()}}>
                            <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
                        </PlayButton>
                        <PauseButton onClick={() => {pauseAudio11()}}>
                            <PauseCircleFilledIcon style={{color: "orange"}}/>
                        </PauseButton>
                    </div>
                    <p><span class="glossary-item">Estados de la materia:</span> Son el estado sólido, el líquido, el gaseoso y el plasma. Se definen de acuerdo a la temperatura de la materia: de sólido, con muy poca libertad de movimiento de las moléculas, a líquido y gas, con mayor libertad de movimiento de ellas. Al incrementarse aún más la temperatura, se llega al cuarto estado de la materia: el plasma.</p>
                </li>
                <li>
                <div style={{position:"absolute", top:"20px"}}>
                        <PlayButton onClick={() => {playAudio12()}}>
                            <PlayCircleFilledWhiteIcon style={{color: "orange"}}/>
                        </PlayButton>
                        <PauseButton onClick={() => {pauseAudio12()}}>
                            <PauseCircleFilledIcon style={{color: "orange"}}/>
                        </PauseButton>
                    </div>
                    <p><span class="glossary-item">Reservas de agua:</span> Las hay de agua dulce y salada. Los océanos y los mares contienen enormes reservas de agua salada, que son las de mayor volumen. Las reservas más importantes de agua dulce son los glaciares, el agua subterránea, los ríos y los lagos.</p>
                </li>
            </ul>
        </section>

  )
}

export default GlosarioEspanol