import React from 'react'
import PropTypes from 'prop-types';
import lightbulb from "../assets/lightbulb.png"
import link from "../assets/link.png"
import { Dialog, DialogTitle, IconButton, Button, DialogContent, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles'
import imagen from "../assets/titulo.png"
import "./content.css"


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

const BootstrapDialog1 = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

const Content = () => {

    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  return (
    <>
    <section className='imagen_portada'>
        <img className='imagen' src={imagen} alt="titulo"></img>
    </section>
    <hr/>
    <section className="sec-body-content-01">
        <div className="sec-body-content-column-text">
            <p className="sec-body-content-text">Uno de los componentes más importantes del proceso de enseñanza, de hecho lo que le da sentido, es que la persona que recibe lo que se quiere comunicar o enseñar... ¡lo entienda! Y para ello es de gran ayuda que lo que se comunica esté en su lengua materna.</p>
            <br/>
            <p className="sec-body-content-text">De lo contrario, cuando en la escuela primaria nos enseñan algo en otra lengua, supone un esfuerzo enorme intentar comprender lo que se dice. En el caso de conceptos científicos que en muchos casos nos pueden ser ajenos, este esfuerzo resulta vano.</p>
            <br/>
            <p className="sec-body-content-text">Dado que la atención a la educación es una de las tareas centrales de la Sociedad Mexicana de Física y que por fortuna existe aún una gran población de compatriotas con lengua materna diferente del español, nos hemos dado a la tarea, junto con el Instituto Nacional de Lenguas Indígenas, de comunicar temas de ciencia, particularmente de física, en las lenguas nacionales que conforman la diversidad cultural de nuestro país.</p>
        </div>
        <div className="sec-body-content-column-pill">
            <div className="info-pill">
                <h3 className="info-pill-title"><img src={lightbulb} alt='light'/>Sabías que...</h3>
                <p className="info-pill-text">La Sociedad Mexicana de Física y el Instituto Nacional de Lenguas Indígenas vienen colaborando para compartir textos científicos en diferentes lenguas indígenas?</p>
                <div className="info-pill-link-container">
                    <Button 
                        variant="outlined"  
                        className="info-pill-link" 
                        color = "primary"
                        sx={{
                            color: "primary",
                            borderRadius: "3rem",
                            border: "solid 2px #e87722"
                        }}
                        onClick={handleClickOpen1}>
                        <img src={link} alt='light'/>Ver Más
                    </Button>
                </div>
                <BootstrapDialog1
                    onClose={handleClose1}
                    aria-labelledby="customized-dialog-title1"
                    open={open1}
                >
                    <BootstrapDialogTitle id="customized-dialog-title1" onClose={handleClose1}>
                        ¿Sabías que
                    </BootstrapDialogTitle>
                        <DialogContent dividers>
                            <Typography 
                              sx={{
                                padding: "0 20px",
                                textAlign: "justify"
                              }} 
                              gutterBottom>
                                La Sociedad Mexicana de Física y el Instituto Nacional de Lenguas Indígenas vienen
                                colaborando para compartir textos científicos en diferentes lenguas indígenas?
                            </Typography>
                            <Typography
                              sx={{
                                padding: "0 20px",
                                textAlign: "justify"
                              }} 
                              gutterBottom>
                                Primero tradujimos seis breves temas: Tomar la mar, Nanotecnología, Próxima centauri b,
                                ¿Cómo se forman las estrellas?, Radiación electromagnética y La gran explosión, a 25
                                lenguas <span><a style={{textDecoration:"none", color: "#e87722"}} href="https://site.inali.gob.mx/SMF/index.html#content4-1n">Link</a></span>
                            </Typography>
            
                            <Typography 
                              sx={{
                                padding: "0 20px",
                                textAlign: "justify"
                              }} 
                              gutterBottom>
                                Ahora hemos preparado el tema del Ciclo Natural del Agua, y está traducido a seis lenguas.
                            </Typography>
                        </DialogContent>
                </BootstrapDialog1>
            </div>
        </div>
    </section>

    <hr/>

<section className="sec-body-content-02">
    <div className="sec-body-content-column-pill">
        <div className="info-pill">
            <h3 className="info-pill-title"><img src={lightbulb} alt='light'/>Sabías que...</h3>
            <p className="info-pill-text">México es de los pocos países que goza al mismo tiempo de una gran diversidad biológica y una gran diversidad cultural, la que se refleja en los 68 idiomas (con 364 variantes) además del español?</p>
            <div className="info-pill-link-container" onClick={handleClickOpen}>
                <Button
                    variant="outlined"  
                    className="info-pill-link" 
                    color= "primary"
                    sx={{
                        color: "primary",
                        borderRadius: "3rem",
                        border: "solid 2px #e87722"
                    }}
                    onClick={handleClickOpen}><img src={link} alt='link'/>Ver Más</Button>
            </div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                ¿Sabías que:
                </BootstrapDialogTitle>
                <DialogContent dividers>
                <Typography 
                  sx={{
                    textAlign: 'justify'
                  }}
                gutterBottom>
                    México es de los pocos países que goza al mismo tiempo de una gran diversidad biológica
                    y una gran diversidad cultural, la que se refleja en los 68 idiomas (con 364 variantes)
                    además del español?
                </Typography>
                <Typography
                sx={{
                  textAlign: 'justify'
                }}
                gutterBottom>
                    Esta diversidad cultural ha permitido que en México se conserve la biodiversidad (ver más: 
                    <span><a style={{textDecoration:"none", color: "#e87722"}} href="https://www.gob.mx/semarnat/articulos/riqueza-cultural-y-biologica-de-
                    mexico#:~:text=La%20diversidad%20natural%20es%20de,fibras%2C%20energ%C3%AD
                    a%20y%20agua"> Link 1</a></span> y <span><a style={{textDecoration:"none", color: "#e87722"}} href="https://biblioteca.semarnat.gob.mx/janium/Documentos/Ciga/Libros2013/CD001600.pdf">Link 2</a></span>)
                </Typography>
                </DialogContent>
            </BootstrapDialog>
        </div>
    </div>
    <div className="sec-body-content-column-text">
        <p className="sec-body-content-text">En México hay 68 lenguas con 364 variantes (<span><a style={{textDecoration:"none", color: "#e87722"}} href="https://www.inali.gob.mx/clin-inali/">https://www.inali.gob.mx/clin-inali/</a></span>); al desaparecer una de ellas, se pierde parte esencial de una cultura. En la Sociedad Mexicana de Física pensamos que ésta es una riqueza nacional que se debe proteger y la mejor manera de proteger una lengua es manteniéndola viva.</p>
        <br/>
        <p className="sec-body-content-text">Continuamos por ello la elaboración de materiales de física que
            sometemos a un proceso de traducción, retro-traducción y difusión en la
            comunidad, a modo de lograr textos sólidos en sus conceptos científicos y que
            sean comprendidos por las comunidades. En esta ocasión describimos el ciclo
            natural del agua por medio de una infografía y lo ofrecemos por ahora en seis
            lenguas nacionales: Chontal (Chontal Alta, de la Sierra de Oaxaca), CH’ol
            (Tumbalá, Chiapas), Mixteco (variante baja de Santiago Amoltepec), Náhuatl
            (Mexicano de la huasteca hidalguense), Popoluca y Tének (del occidente).
            Nuestro objetivo es que en las escuelas de las comunidades respectivas, las
            niñas y los niños puedan aprender el ciclo del agua y describir los conceptos en
            su lengua ¡y que lo disfruten y entiendan!</p>
        <br/>
        <p className="sec-body-content-text">
        Las versiones impresas de la infografía se entregan a las respectivas comunidades según la demanda. Las versiones en línea incluyen un glosario, así como la versión oral de los textos escritos, a modo de facilitar su comprensión y apoyar la uniformización de la lengua escrita.
        </p>
        <br/>
        <p className="sec-body-content-text">
        Compañera o compañero hablante, si hay interés de trabajar el material
    en tu lengua, ponte en contacto con la Sociedad Mexicana de Física,
    smf.admin@ciencias.unam.mx .
        </p>
    </div>
</section>

<hr/>

<h2>Créditos</h2>

<section className="sec-credits">
    <p style={{textAlign: "justify", lineHeight:"1.5"}}>
    Extendemos un reconocimiento a todas las personas que han colaborado
en el desarrollo del presente proyecto: Diseñadora Sandra Rangel, para el
Chontal Lengua Alta, de la Sierra de Oaxaca, Físic@s: Lucía Eréndira Gutiérrez Luna y
Víctor Manuel Jaramillo Pérez, Traductor@s: Cipriano Vázquez Zárate y
Roselia Vázquez Zárate; para el CH’ol de Tumbalá, Chiapas, Físic@s: Laura
Olivia Villegas Olvera y Alejandro Aguilar Nieto, Traductora: Erika Sarí Vázquez
Gómez; para el Mixteco: Físicas: Melissa Díaz Herrera y Charmina Aguirre
Alvarado, Traductora: Petronila Pérez Velazco Ita Sabi; para el Náhuatl de
Hidalgo, Físicas: Laura Olivia Villegas Olvera y Claudia Moreno, Traductora: Irla
Villegas; para el Popoluca de la Sierra de Veracruz Físic@s: Lucía Eréndira
Gutiérrez Luna y Juan Carlos Degollado Daza; para el Tének del occidente,
Físico: Darío Núñez, Traductor: Luis Flores. Así mismo, agradecemos el apoyo
de la empresa Neture WS encargada del diseño y desarrollo de esta página web. <br /> <br />
Con nuestros deseos que sea útil y lo disfrutes, Darío Núñez, Ana María Cetto, Ana Cecilia Conde, Luis Flores y Zazil
Sandoval.
    </p>
</section>
<hr/>
</>
)
}

export default Content