import { Paper } from '@mui/material';

function Item({item})
{
    return (
        <Paper>
            <img className='slide-image' src={item.urls} alt={item.title} style={{width: "100%", height: "60vh"}}/>
        </Paper>
    )
}

export default Item;