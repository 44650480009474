import React from 'react'
import './glosario.css'

const GlosarioPopoluka = () => {
  return (
    <section id="popoluca" className="tabcontent">
            <h2>Glosario Popoluca</h2>
            <ul className="glossary-list">
                <li>
                    <p><span className="glossary-item">Je’m tung ikuwitsetpa je’m nii’:</span> Je’m tung ikuwitsetpa je’m nii’: je’ tuum wityi’ di je’m nii’ naspa’ap xajajoom, angtiwiñyuk di yip naxyukmi, moam lamaar y je’m naskim. Yip sii’ akniyijtya’ap kuwitsetyi’ iga nakupoyxettya’ap, moam dya’ in i’ jutyi tsukumpa i ju’u kuyajpa’.</p>
                    <br/>
                    <p className="text-small">Jeyuk, wiap tan niim iga je’m nii’ i ukyajwib je’m tan tsa’matay (olmecayaj) i je’m tan uktampa’ab ta’ ichtyam je’ jetyim.</p>
                </li>
                <li>
                    <p><span className="glossary-item">Jubik wiab tang kisp’ tyiam pijpa i tyiam pagakpig:</span> Je tuum kipsi’ di tyiam tsigoypa’ di je’m tyityampig ini ’je’m mijtay.. Jesik je’m termómetro i kispa i nukpa a ips gradu’ jesikgam je’m nii’ jokoasetpa’ i jesik i nukpa a 0 gradu’ tsampa’. Yiam naspa jesik dya taityan jutyi dya’m agui yukmi i dyatyim agui tsapa’ di je’m lammar. Jesik kipspa je’m pigtap je’m grado Celsius.</p>
                </li>
                <li>
                    <p><span className="glossary-item">omajtuj:</span> jesik je’m nii’joko’ angkakpa’ i nii’am tsiop. Je’m nii’joko’ kimpa’ singyuk kupagaka’ap, nii’asetpa’, wintyi’ je’m xutyu’ miiktuj niitsama’ jempigam nawattap je’m uksajyaj.</p>
                </li>
                <li>
                    <p><span className="glossary-item">Jesik angtiinnpa’ je’m nii’:</span> jesik angtikpa’ je’m nii’ ukxajoom’, tsiop kumu tu’u, miiktu’u, i je’m, nii’tsama’ i je’m ikstyu’u. Jempigam je’m nii’ setpa naxyuk. Moam ma’a jayang aangtinpa kumu tu’u.</p>
                </li>
                <li>
                    <p><span className="glossary-item">Ku’ospa’:</span> jesik je’m nii’ ityiwib je’m kuyuangjoom kujogaaypa’. Je’m nii’ tsukumpa’ di ityichikjoom, nasapa’ je’m xutyu’josoom ityiwip je’m ay ityunijoom, jemum tsiop kumu joko’ i jeam nikpa sinyukmi’.</p>
                </li>
                <li>
                    <p><span className="glossary-item">Jesik je’m nii’ tikib naskim:</span> Jesik je’m nii’ miñpa’ angnasmi’di yip naxyukmi’ i nikpa je’m tsa’angjoom, je’m nasjoom jutyi jobityi i simtits. Jeam nasjin jutyke’ di yip nasyukmi’, moam usang di je’m nii’ kuospaap simtitspa’ jem widyay tsa’nagjoom y je’m naskim.</p>
                </li>
                <li>
                    <p><span className="glossary-item">Je’m nii’ simtitspa:</span> Naspa’ jesik je’m nii’ ik tsiypabig nas i kusimnaspa’ i naspa naskukim.Usang di je’m nii’ i simtitspa’ i usang naspa’ap angnasmi’ (<strong>teknina’asi’</strong>) je’ jesap iga justpiy wiab i simtits moam jutspiy nejne je’m kamjoom.</p>
                </li>
                <li>
                    <p><span className="glossary-item">nii’tekninaspa’:</span> jesik je’m nii’ miñpa di je’m tujyaj u je’m niitsamayuk naspa’ap angnasmi’ di yip nas, jeyuk jesik dyam wiab i simtsit’ je’m nii’ i dyamtyim kujogayapa’ je’m nii’’.</p>
                    <br/>
                    <p><span className="glossary-item">nii’tekninaspa angnasmi’:</span> je’m tujnii’ angtinewib i naspa’ap angnasmi’ di yip nasjoom’ pur je’m i tsakñiaji di je’m nii’, dyam i’ simtsits’ naskim.</p>
                </li>
                <li>
                    <p><span className="glossary-item">Nii’naskimpig ityi’:</span> je’m nii’ simpabig naxjoom i tsiiypa’ nas angxikmi’. Wiap tan pat je’m watñaxjom di yip naxyuk i ñiiyipig nii’tuumi’ ityiwib naskim.’ Je’m nii’naskimpig ityi je’ tuum xaja’a agui’ wibik pa ́je’m idyao’oyi di je’m pixintyam i yomtam i je’m ityumpiy wado’oyi.</p>
                </li>
                <li>
                    <p><span className="glossary-item">Kujogaypa’:</span> je jesik kukagpa’a je’m nii’ i tsiop di nii’ a jokonii’. Je’m kujogaypa je’m nii’ tsukumpa’apn lamaar tikig je’m angtiwinyuk jesik jokonii’.</p>
                </li>
                <li>
                    <p><span className="glossary-item">Jutyipig tan patpa’ je’m kukeji’:</span> je’ jesik kama’am, jesik nii’, jesik jo’oko i jesik kumu’ o’oma’.. Jesik kama’am, kun tyityam nawatneti kaasi’ dya’ wi’ab i tsikooy’, iga nii’ a setpa’ i okmi jokoasetpa’ i kun tyityam nawtnteaj jesik ma’ jikskiy tsikoob. Jeyuk pimkimpa’a ma’, jutsaab tan kutyiiyiy sii’ agui’ pijpa’. jesabaam iga nukpa’ je’m mostenpig ityi di yip iniiyipig kumu’ o’oma’.</p>
                </li>
                <li>
                    <p><span className="glossary-item">Nii’tumi’ jayangpig ityi:</span> tan patpa’ je’m nii’ wibik tan uk y je’m paknii’. Je’m widya’nii’ i lamaarnii’ i ni ityiyaj ja’ayang nii’ i ni itywip kana’ i jeam agui’ ja’ayang. Je’m nii’ tumnewip ma’ wibig jeam je’m ityi wib, je’n nii’ ityi wib naskukim, je’m nii’tsamakim, je’m nii’poyakim  i je’m nii’tumnewib</p>
                </li>
            </ul>
        </section>

  )
}

export default GlosarioPopoluka