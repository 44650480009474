import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Typography, ImageList, ImageListItem } from "@mui/material";
import Grid from '@mui/material/Grid';
import SMFLogo from "../Logo_SMF_Horizontal.png"
import INALILogo from "../Logo_INALI.png"
import { Link } from 'react-router-dom';

const ImageListStyled = styled(ImageList)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center"
  },
}));


const linkSMF = 'https://www.smf.mx/' 
const linkINALI = 'https://www.inali.gob.mx/' 

function Footer() {
  return (
    <Box position="static" sx={{ flexGrow: 1}} backgroundColor="white">
      <Grid container spacing={2} columns={24}>
        <Grid item xs={24} md={11} alignItems="center">
        <Box display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <ImageListStyled >
            <ImageListItem sx={{margin: "0 4"}}>
              <Link to={linkSMF}>
                <img src={SMFLogo} alt="logo_SMF" width="180px"/>
              </Link>
            </ImageListItem>
            <ImageListItem sx={{marginTop:2, marginLeft:2, marginRight:2}}>
              <Link to={linkINALI}>
                <img src={INALILogo} alt="logo_SMF" width="180px"/>
              </Link>
            </ImageListItem>
          </ImageListStyled>
        </Box>
        </Grid>
        <Grid item xs={24} md={2}>
        </Grid>
        <Grid item xs={24} md={11} sx={{marginBottom:2, paddingLeft:30, color:"black"}}>
        <Typography color="textSecondary" variant="subtitle1" textAlign="left" sx={{color:"black", margin:"0 1rem"}}>
               Departamento de Física, 2do piso, Facultad de Ciencias, UNAM, C.P. 04510 Delegación Coyoacán, Ciudad Universitaria, CDMX
        </Typography>
        <Typography color="textSecondary" variant="subtitle1" textAlign="left" sx={{color:"black", margin:"0 1rem"}}>
               (+52) 55 5622 4946
        </Typography>
        <Typography color="textSecondary" variant="subtitle1" textAlign="left" sx={{color:"black", margin:"0 1rem"}}>
               (+52) 55 5622 4848
        </Typography>
        <Typography color="textSecondary" variant="subtitle1" textAlign="left" sx={{color:"black", margin:"0 1rem"}}>
               smf.admin@ciencias.unam.mx
        </Typography>
        </Grid>
        <Grid item xs={24} md={24} backgroundColor="rgb(232, 119, 34)" >
        <Typography color="black" variant="subtitle1" textAlign="center" sx={{marginBottom:2, color:"white"}}>
               Copyright 2023. Todos los derechos reservados NetureWS
        </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Footer;