import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Indice from '../components/Indice'

const Infografias = () => {
  return (
    <>  
        <Navbar/>
        <Indice/>
        <Footer/>
    </>
  )
}

export default Infografias